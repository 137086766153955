.variableModalInput{
    padding: 1%;
}
.mainContent{
    display: flex;
}

.searchPart{
    flex: 1;
    /* padding: 2.75% 2%; */
    padding: 0  2%;
    justify-content: center;
    align-items: center;
}

.viewPart{
    flex: 2.5;
    margin: auto;
    text-align: center;
    max-height: 560px;
    overflow-y: scroll;
}

.cardContainer{
    width: 300px;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    text-align: center;
    padding: 10% 6%;
    border: 1px solid black;
}

.tempHeading{
    padding-top: 1%;
    padding-left: 2%;
}

.ant-btn-dashed{
    color: orange !important;
    border-color: orange !important;

}
.ant-btn-dashed:hover{
    color: #EA7327 !important;

    border-color: #EA7327 !important;
}

/* style={{
    paddingTop: "1%",
    paddingRight: "2%",
    marginTop: "10px",
    overflowY: "scroll",
  }} */

  .modalEdit{
      color: #EA7327;
  }
  .modalEditNew{
    color: #94928f;
}
  .modalEdit:hover{
      color: saddlebrown;
  }