
  /* .message{
    display: none;
  } */

@media only screen and (max-width: 900px){
  .App{
    overflow-x: scroll;
  }
  /* .message{
    display: block;
    text-align: center;
    height: 100vh;
    margin:0 auto;
    font-size: 24px;
    color: darkorange;
    font-weight: 600;
    padding: 20px;
  } */
}
