.smsContainer{
    padding: 2% 2% 1.7% 2%;
}

.smscontent{
    display: flex;
    gap: 20px;
}
.smsHeader{
   padding-bottom: 50px;
}
.firstpart{
    flex: 2;
    margin: auto;
    text-align: center;
}
#textAreaSms{
    border: black 1px solid;
    border-radius: 5px;
    box-shadow: 2px 3px 10px #bebebe, -1px -1px 10px #ffffff;
    height: 299px;
    width: 850px;
    overflow-y: scroll;
    word-wrap: break-word;
    /* background: url(http://i.imgur.com/2cOaJ.png);
  background-attachment: local;
  background-repeat: no-repeat;
  padding-left: 35px;
  padding-top: 10px;
  border-color: #ccc; */
}
#codeArea{
}
.editor{
    
    word-wrap: break-word;
    
}

.secondpart{
    flex: 1;
}
.saveTempBtn{
    width: 250px;
}
.saveTempBtn:enabled:hover{
    transform: scale(1.1);
    transition-duration: 500ms;
}
.saveTempBtn:disabled:hover{
    background-color: #EEEEEE !important;
    border: 1px #EEEEEE solid;
}