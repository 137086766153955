.mainContent{
    display: flex;
}

.searchPart{
    flex: 1;
    padding: 2%;
    justify-content: center;
    align-items: center;
}

.viewPart{
    flex: 2.5;
    text-align: center;
    max-height: 560px;
    overflow-y: scroll;
}
.noDataImage{
    margin-left: 200px;
    opacity: 0.4;
    text-align: center;
    
}

.cardContainer{
    width: 300px;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    text-align: center;
    padding: 10% 5%;
    border: 1px solid black;
}
#cardlist{
    border-radius: 5px;
    width: 250px;
    /* height: 180px; */
    border-color: gray;
    
    
    /* background: #e0e0e0; */
box-shadow:  12px 12px 4px #cacaca,
             -1px -1px 40px #f6f6f6;
    
}

#cardlist:hover{
    cursor: default;
}

.ant-btn-dashed{
    color: orange !important;
    border-color: orange !important;

}
.ant-btn-dashed:hover{
    color: #EA7327 !important;

    border-color: #EA7327 !important;
}

.ant-btn:focus {
    color: #000;
    border-color: #000;
}

.ant-modal-content{
    border-radius: 10px !important;
  }
  .ant-modal-header{
    border-radius: 10px 10px 0 0  !important;
  }
  
  .ant-btn-default:focus{
      border-color: #EA7327 ;
    color: #EA7327    ;
  }
.pagination{

    width: 100%;
    
}

.dataWrapper {

    display: grid;
    /* grid-template-columns: repeat(3, 1fr); */
    grid-template-columns: repeat(auto-fill, minmax(230px,1fr));
    gap: 30px;
    grid-auto-rows: auto;
    justify-items: center;
    
  }

  .paginationWrapper{
      padding: 2%;
  }

#allTempModal{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}
.column{
   border: 1px solid rgb(224, 219, 219);
}

.ant-table-thead .ant-table-cell {
    background-color: #dfdbd5 ;
    /* border: 1px solid rgb(88, 85, 85); */
  }

  .example {
      align-items: center;
    margin: 200px 200px;
    margin-bottom: 20px;
    padding: 30px 50px;
    text-align: center;
    border-radius: 4px;
  }

  