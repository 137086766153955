.dividedContainer{
  /* background-color: aquamarine; */
    display: flex;
    flex-direction: column;
    /* gap: 20px; */
    height:calc(100vh - 9vh);
    justify-content: space-between;
    /* padding: 5%; */
}

.imageComp{
    
    text-align: center;   
    /* border-right: #ED7300 3px ridge; */

}

#footerImg{
    /* margin-top: 98px; */
    /* position: absolute; */
    
}

#cardComp {
  display: flex;
  margin-top: 50px;
  /* border-radius: 10px;
  background: #ffffff;
  box-shadow:  20px 40px 40px #d9d9d9,
               -20px -20px 40px #ffffff; */
}

#aCont {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  text-align: center;
}
#cardOne {
  width: 300;
  border-color: gray;
  height: 200;
  margin: auto;
  border-radius: 21px;
  background: #ffffff;
  box-shadow:  5px 5px 10px #d9d9d9,
               -5px -5px 10px #ffffff;
}
#icon {
  font-size: 64px;
  color: #ED7300;
}

#cardTwo {
  width: 300;
  height: 200;
  margin: auto;
  border-color: gray;

  border-radius: 21px;
  /* background: #ffffff; */
  
  box-shadow:  5px 2px 10px #d9d9d9,
               -5px -5px 5px #ffffff;
}

#cardOne:hover{
  background-image: linear-gradient(0deg, rgba(237, 115, 0, 0.09) 3%, rgba(255, 169, 77, 0.02) 20%);
}
#cardTwo:hover{
  background-image: linear-gradient(0deg, rgba(237, 115, 0, 0.09) 3%, rgba(255, 169, 77, 0.02) 20%);
}

.ant-radio-button-checked{
  background-color: #ED7300 !important;
  outline-color: #ED7300;
}
.ant-radio-button:hover{
  color: #ED7300 !important;
}
.addbutton:hover {
  color: #ed7300 !important;
  border-color: #ed7300 !important;
}

.copyBtn:hover {
  color: #ed7300 !important;
  border-color: #ed7300 !important;
}
.ant-btn-primary {
  background-color: #ed7300 !important;
  border-color: white;
}

.ant-btn-primary:hover {
  color: white;
  border-color: black;
}

