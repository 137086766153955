@import "antd/dist/antd.css";

.header-order-div {
  background-color: white;
  box-shadow: 1px 2px 3px #ccc;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btnContainer{
  margin-right: 10px;
  display: flex;
  
  align-items: center;
}

.pageName{
  display: block;
  flex: 1  ;
  /* margin-left: 200px; */
  text-align: center;
}

.brand-logo,
.brand-logo-ie {
  height: 40px;
}
.ant-layout-header {
  padding: 4px;
  height: 52px;
}
.header-title {
  font-size: 32px;
}
.ant-input:hover {
  border: 1px solid transparent;
}
.column-details {
  word-break: break-word;
}
.logged-user {
  float: right;
  align-items: right;
}

.ant-btn-link{
  color: #ed7300 !important;
}

.ant-btn-primary[disabled]{
  color: #CEABAB !important;
  background-color: #EEEEEE !important;
}

@media (max-width: 425px) {
  .ant-layout-header {
    padding: 0;
    height: 35px;
  }
  .brand-logo {
    height: 35px;
    position: absolute;
    top: 0;
    left: 0;
  }
}
@media (max-width: 768px) and (min-width: 425px) {
  .ant-layout-header {
    padding: 0;
    height: 50px;
  }
  .brand-logo {
    height: 35px;
    position: absolute;
    top: 0px;
    left: 0;
  }
}
@media (max-width: 1024px) and (min-width: 769px) {
  .ant-layout-header {
    height: 82px;
  }
  .header-title {
    font-size: 32px;
  }
  .header-subtitle {
    font-size: 20px;
  }
  .ant-layout-header {
    padding: 0;
    height: 70px;
  }
  .brand-logo {
    height: 35px;
    position: absolute;
    top: 0px;
    left: 0;
  }
}

.logout-button {
  /* color: #ed7300 !important;
  border: black 1px solid; */

  background-color: initial;
  background-image: linear-gradient(-180deg, #FF7E31, #E62C03);
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 2px 4px;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: Inter,-apple-system,system-ui,Roboto,"Helvetica Neue",Arial,sans-serif;
  height: 40px;
  line-height: 40px;
  outline: 0;
  overflow: hidden;
  padding: 0 22px;
  pointer-events: auto;
  position: relative;
  text-align: center;
  touch-action: manipulation;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: top;
  white-space: nowrap;
  width: 100%;
  z-index: 9;
  border: 0;
  transition: box-shadow .2s;
}
.logout-button:hover{
  /* border: #ed7300 1px solid ; */
  box-shadow: rgba(253, 76, 0, 0.5) 0 3px 8px;
}

.homeBtn{
  border-radius: 10px 0 10px 0 !important;
}


.ant-modal-header {
  border-bottom: black 1px solid !important;
}
.ant-modal-footer {
  border-top: black 1px solid !important;
}