.card-center {
  height: 60vh; /* Magic here */
  border-color: rgb(224, 224, 226);
  margin: 20px;
  border-radius: 5px;
  border-width: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button {
  border-radius: 20px;
}

.brand_logo {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-left: 15px; */
}

.card {
  display: flex;
  justify-content: center;
  align-items: center;
}

.addbutton {
  margin-top: 10px;
}
