
@import url('https://fonts.googleapis.com/css2?family=Pacifico&family=Roboto+Serif:wght@300&family=Dosis&display=swap');
.ant-radio-checked .ant-radio-inner {
  border-color: #ED7300 !important ;
}

.ant-radio-checked .ant-radio-inner:after {
  background-color: #ED7300;
}

.ant-radio:hover .ant-radio-inner {
  border-color: #ED7300;
}

.ant-btn-default{
  border: black 1px solid;
}
.ant-btn-default:hover{
color: #ED7300 !important;
border: #ED7300 1px solid;
}

.ant-radio-button-wrapper:hover{
  color: #ED7300;
}


.backBtnContainer{
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: fixed;
  padding: 2% 4% 0 4%;
}

.headingContainer p{
  color: #ED7300;
  font-size: x-large;
  /* font-family: 'Pacifico', cursive; */
  font-family: 'Roboto Serif', serif;
}


#master{

  z-index: -1;
}

.templateContainer{
  text-align: center;
}

.saveTempBtn:hover{
  background-color: rgba(237, 114, 0, 0.911) !important;
  border: transparent;
  scale: (0.9,0.9)
}

.saveTempBtn{
  /* position: sticky;
  bottom: 0;
   */
   margin-top: 20px;
  z-index: 2;
  /* float: right; */
  /* right: 100px;
  bottom: 100px; */
  border-radius: 10px;
  /* background-color: #ed7200fb; */

}

/* .modContainer {
  height: 240px;
  opacity: 0.7;
  backdrop-filter: blur(50px) saturate(126%);
  -webkit-backdrop-filter: blur(50px) saturate(126%);
  background-color: red;
  border-radius: 12px;
  border: 1px solid rgba(209, 213, 219, 0.3);
} */

#input{
  border-radius: 5px;
  border: 1px solid gray;
}