#content{
  height: 81.5vh;
}
#row {
  padding: 4%;
}
#col1 {
  margin: auto;
}

.sider {
  margin: auto;
  padding: 2%;
  display: flex;
  flex-direction: column;
  gap: 40px;
  justify-content: center;
}
.code {
  display: flex;
  justify-content: center;
  align-items: center;
}
#sourceHead {
  margin: auto;
}
#iconArrow {
  margin: auto;
  font-size: 20px;
}
.uploadContainer {
  text-align: center;
}


#textAreaSource {
  margin-bottom: 50px;
  border-radius: 5px;
  height: 300px;
  /* background-color: aquamarine; */
  overflow-y: scroll;
  overflow-x: scroll;
  /* box-shadow: 20px 20px 60px #bebebe, -20px -20px 60px #ffffff; */
  /* background: url(http://i.imgur.com/2cOaJ.png);
  background-attachment: local;
  background-repeat: no-repeat;
  padding-left: 35px;
  padding-top: 2px; */
  
  border: 1px solid black;
}

.editor {
  counter-reset: line;
  /* border: 1px solid #656668; */
  min-height: 300px;
  /* background-color: blue; */

}

.editor #codeArea {
  outline: none;
  padding-left: 60px !important;

}

.editor pre {
  padding-left: 60px !important;
}

.editor .editorLineNumber {
  position: absolute;
  left: 0px;
  color: #cccccc;
  text-align: right;
  width: 40px;
  font-weight: 100;
}
