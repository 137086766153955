.card {
  display: flex;
  justify-content: center;
  align-items: center;
}

.top {
  margin-top: 50px;
}

.homeCardContainer{
  display: flex;
  justify-content: space-evenly;
  padding-top: 0.8%;
  padding-bottom: 3%;
}
.ant-card-hoverable:hover{
  box-shadow: 0 1px 2px -2px rgb(0 0 0 / 16%), 0 3px 6px 0 #ed720080, 0 5px 12px 4px rgb(0 0 0 / 9%) !important;
}

.cardprops{
  border-radius: 10px;
  border-color: black;
  transition-property: transform;
  transition-timing-function: ease;
}

.cardprops:hover {
  transform: scale(0.9, 0.9);
  transition-duration: 800ms;
  
}